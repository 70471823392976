<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FormFieldSelect
        v-model:value="values.company"
        name="company"
        label="Компания"
        :error="errors.company"
        :options="companyOptions"
      />
      <FormField
        v-model:value="values.name"
        name="name"
        label="Имя"
        :error="errors.name"
      />
      <div class="row-cols-2">
        <div>
          <FormFieldOptionsSwitcher
            v-model:value="values.type"
            label="Тип продукта"
            :options="ProductTypeOptions"
          />
          <FormFieldOptionsSwitcher
            v-if="isLeasing"
            v-model:value="values.currency"
            label="Валюта"
            :error="errors.currency"
            :options="CurrencyOptions"
          />
        </div>
        <div>
          <FormFieldMultiSelect
            v-model:selected-options="values.carTypes"
            label="Тип автомобиля"
            :options="ProductCarTypeOptions"
            :searchable="false"
            :no-error-padding="true"
          />
          <br />
          <FormFieldOptionsSwitcher
            v-model:value="values.carElectricType"
            :options="ProductCarElectricTypeOptions"
          />
        </div>
      </div>
      <hr />

      <div class="row-cols-3">
        <FormFieldMinMax
          v-model:value="values.amount"
          label="Сумма программы"
        />
        <FormFieldMinMax
          v-model:value="values.period"
          name="period"
          label="Срок действия программы, мес."
        />
        <FormFieldMinMax
          v-if="withAmp"
          v-model:value="values.carAge"
          name="carAge"
          label="Возраст авто, лет"
        />
      </div>

      <div class="row-cols-2">
        <FormField
          v-model:value="values.percent"
          :disabled="extendedPercentsEnabled"
          name="percent"
          label="Процентная ставка, %"
          :error="errors.percent"
        />
        <div class="row-checkboxes">
          <FormFieldCheckbox
            v-model:checked="values.discountEnabled"
            label="Льготный период"
            name="withDiscountPeriod"
          />
          <FormFieldCheckbox
            v-if="withAmp"
            v-model:checked="values.percentDependsOnAge"
            label="Ставка зависит от возраста авто"
            name="percentDependsOnAge"
          />
        </div>
      </div>

      <DynamicField
        v-if="extendedPercentsEnabled"
        :field="extendedPercentsField"
      />

      <div v-if="values.discountEnabled" class="row-cols-2">
        <FormField
          v-model:value="values.discountPeriod"
          label="Льготный период, мес."
          type="number"
        />
        <FormField
          v-model:value="values.discountPercent"
          name="percentDiscount"
          label="Льготная процентная ставка, %"
          type="number"
          :disabled="extendedPercentsEnabled"
        />
      </div>

      <div class="row-cols-2">
        <FormField
          v-model:value="values.advancePaymentMin"
          label="Минимальный аванс, %"
          :no-error-padding="true"
          type="number"
        />
        <FormField
          v-model:value="values.earlyPaymentMonthsMin"
          label="Досрочное погашение после мес."
          type="number"
        />
      </div>

      <div v-if="isLeasing" class="row-cols-2">
        <FormField
          v-model:value="values.coefficientSpeedUp"
          label="Коэффициент ускорения"
          :no-error-padding="true"
          type="number"
        />
        <FormField
          v-model:value="values.redemptionPercent"
          label="Выкупной платеж, %"
          :no-error-padding="true"
          type="number"
        />
      </div>

      <hr />

      <FormFieldOptionsSwitcher
        v-model:value="values.paymentType"
        label="Тип платежа"
        :options="ProductPaymentTypeOptions"
      />

      <FormFieldOptionsSwitcher
        v-model:value="values.collateralType"
        label="Тип обеспечения"
        :options="ProductCollateralTypeOptions"
      />

      <FormFieldOptionsSwitcherTrueFalse
        v-model:value="values.kaskoRequired"
        label="Обязательно оформление КАСКО"
      />

      <FormField
        v-model:value="values.conditions"
        label="Прочие условия"
        type="textarea"
      />
    </form>
    <template #footer>
      <FormFooter
        :is-creation="isCreation"
        :can-create-another="isCreation"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FormFieldCheckbox,
  FormFieldMinMax,
  FormFieldMultiSelect,
  FormFieldOptionsSwitcher,
  FormFieldOptionsSwitcherTrueFalse,
  FormFieldSelect,
  FormFooter,
  OptionType,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { createProduct, getProduct, updateProduct } from "@/modules/products";
import { ProductFullInterface } from "@/modules/products/typings";
import {
  getProductsListUrl,
  getProductsUpdateUrl,
} from "@/modules/products/links";
import { getCompaniesList } from "@/modules/companies/requests";
import {
  CurrencyOptions,
  ProductCarElectricTypeOptions,
  ProductCarType,
  ProductCarTypeOptions,
  ProductCollateralTypeOptions,
  ProductPaymentTypeOptions,
  ProductType,
  ProductTypeOptions,
} from "@/modules/products/enums";

import {
  convertFormValuesToProductSavePayload,
  convertProductToFormValues,
  createFieldExtendedPercentsConfig,
  FormValues,
} from "./ProductsForm.helpers";

export default defineComponent({
  name: "ProductsForm",
  components: {
    DynamicField,
    Page,
    FormFooter,
    FormField,
    FormFieldSelect,
    FormFieldMinMax,
    FormFieldMultiSelect,
    FormFieldOptionsSwitcher,
    FormFieldOptionsSwitcherTrueFalse,
    FormFieldCheckbox,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed<string>(() => route.params.id as string);
    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource<
      Nullable<ProductFullInterface>
    >({
      fetchResource: () => getProduct(id.value),
      initialValue: null,
      resourceName: "Продукт",
    });

    const [fetchCompanies, { data: companies, loading: isCompaniesLoading }] =
      useResource({
        fetchResource: () => getCompaniesList(),
        initialValue: null,
        resourceName: "Компании",
      });

    const companyOptions = computed<Array<OptionType<number>>>(() => {
      return (
        companies.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      );
    });

    const values = ref<FormValues>(convertProductToFormValues(model.value));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    onMounted(() => {
      if (route.params.id !== "create") {
        fetchModel();
      }

      fetchCompanies();
    });

    watch(model, () => {
      values.value = convertProductToFormValues(model.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const savePayload = convertFormValuesToProductSavePayload(
        values.value,
        extendedPercentsField.value as RepeaterField
      );

      const requestPromise = isCreation.value
        ? createProduct(savePayload)
        : updateProduct(id.value, savePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getProductsUpdateUrl(response.data.id));
          } else if (
            event.type === "create_exit" ||
            event.type === "save_exit"
          ) {
            router.push(getProductsListUrl());
          } else if (event.type === "create_create-another") {
            values.value = convertProductToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: "Продукт успешно сохранен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при сохранении продукта",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => isModelLoading.value || isCompaniesLoading.value
    );

    const pageTitle = computed<string>(() => {
      return isCreation.value
        ? `Новый продукт`
        : `Редактирование продукта ${
            model.value?.name ? '"' + model.value.name + '"' : ""
          }`;
    });

    const isLeasing = computed<boolean>(
      () => values.value.type === ProductType.Leasing
    );

    const withDiscount = computed<boolean>(() => values.value.discountEnabled);
    const extendedPercentsEnabled = computed<boolean>(
      () => withAmp.value && values.value.percentDependsOnAge
    );
    const withAmp = computed<boolean>(
      () =>
        !!values.value.carTypes.find(
          (item) => item.value === ProductCarType.Amp
        )
    );

    const extendedPercentsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(
        createFieldExtendedPercentsConfig(false),
        []
      )
    );

    function updateExtendedPercents() {
      const incomingFieldList: RepeaterIncomingValue = model.value
        ?.extendedPercents
        ? model.value?.extendedPercents.map((item) => {
            return [
              {
                name: "minAge",
                value: item.minAge,
              },
              {
                name: "maxAge",
                value: item.maxAge,
              },
              {
                name: "percent",
                value: item.percent,
              },
              {
                name: "discountPercent",
                value: item.discountPercent,
              },
            ];
          })
        : [];

      extendedPercentsField.value = universalFieldUtils.createFormField(
        createFieldExtendedPercentsConfig(withDiscount.value),
        incomingFieldList
      );
    }

    watch([model, withDiscount], () => {
      updateExtendedPercents();
    });

    return {
      model,
      isLeasing,
      withAmp,
      extendedPercentsEnabled,

      pageTitle,
      isContentLoading,
      values,
      errors,
      isCreation,

      submitForm,
      backButtonUrl: getProductsListUrl(),
      isSubmitting,

      extendedPercentsField,
      companyOptions,

      ProductTypeOptions,
      ProductCarTypeOptions,
      CurrencyOptions,
      ProductPaymentTypeOptions,
      ProductCollateralTypeOptions,
      ProductCarElectricTypeOptions,
    };
  },
});
</script>

<style>
.row-checkboxes {
  display: flex;
  gap: 2rem;
  margin-top: 2.2rem;
}
</style>
